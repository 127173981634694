// React
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Modal
import { Modal } from '../../../../Contexts/Modals'

import ToolTipBottom from '../../ToolTips/ToolTipBottom'

// MUI Imports
import { Home } from '@mui/icons-material'

// Contexts
import { useSpotifyControlContext } from '../../../../Contexts/SpotifyControlContext'
import { useThemeContext } from '../../../../Contexts/ThemeContext'

// Packages
import { useSnackbar } from 'notistack'

// Stylings
import './HostFormModal.scss'

type HostFormModalProps = {
  explicitMusic: boolean
  setExplicitMusic: Dispatch<SetStateAction<boolean>>
  setSpotifyReady: Dispatch<SetStateAction<boolean>>
  setSubmitted: Dispatch<SetStateAction<boolean>>
  spotifyReady: boolean
  submitSuccessful: boolean
}

const HostFormModal = (props: HostFormModalProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar()
  const { testCommands, setPlayerState } = useSpotifyControlContext()
  const { primaryColor } = useThemeContext()
  const navigate = useNavigate()

  const [explicitOffSlider, setExplicitOffSlider] = useState<string>('')
  const [explicitOnSlider, setExplicitOnSlider] = useState<string>('start')
  const [fadeOut, setFadeOut] = useState<string>('')
  const [animate, setAnimate] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(true)
  const [toolTipMessage, setToolTipMessage] = useState<string>(
    'Please check spotify',
  )

  useEffect(() => {
    if (props.spotifyReady) {
      setDisabled(false)
      setToolTipMessage('Continue to Sync')
    } else if (!props.spotifyReady) {
      setDisabled(true)
      setToolTipMessage('Please check spotify')
    }
  }, [ props.spotifyReady ])

  const explictOn = () => {
    props.setExplicitMusic(true)
    setExplicitOffSlider('animate-off')
    setExplicitOnSlider('animate-on')
  }

  const explictOff = () => {
    props.setExplicitMusic(false)
    setExplicitOffSlider('animate-on')
    setExplicitOnSlider('animate-off')
  }

  const checkSpotify = async (e: any) => {
    setAnimate('animate')
    setTimeout(() => {
      setAnimate('')
    }, 500)
    const ready = await testCommands.pause()
    if (!ready) {
      enqueueSnackbar('Unable to connect to Spotify', {
        autoHideDuration: 3000,
        variant: 'error',
      })
    } else {
      enqueueSnackbar('Connected to Spotify', {
        autoHideDuration: 3000,
        variant: 'success',
      })
      setPlayerState(prev => {
        return {
          ...prev,
          playing: false,
        }
      })
      props.setSpotifyReady(ready)
      props.setSubmitted(true)
      setFadeOut('fade-out-2')
    }
  }

  return (
    <Modal fadeOut={fadeOut}>
      {!fadeOut && (
        <div className={`host-form__container`}>
          <div
            className='home-icon__container'
            onClick={() => navigate('/home')}
            style={{
              backgroundColor: primaryColor,
            }}
          >
            <Home className='home-icon' />
          </div>
          <div className='form-buttons__container'>
            <form>
              <section>
                <p
                  style={{
                    color: primaryColor,
                  }}
                >
                  Explicit Music
                </p>
              </section>
              <section>
                <div onClick={() => explictOn()}>
                  <p>On</p>
                  <div
                    className={`explicit-on__slider ${explicitOnSlider}`}
                    style={{
                      backgroundColor: primaryColor,
                    }}
                  ></div>
                </div>
                <div onClick={() => explictOff()}>
                  <p>Off</p>
                  <div
                    className={`explicit-off__slider ${explicitOffSlider}`}
                    style={{
                      backgroundColor: primaryColor,
                    }}
                  ></div>
                </div>
              </section>
            </form>
          </div>
          <div className='how-to__container'>
            <div>
              <p
                style={{
                  color: primaryColor,
                }}
              >
                Get Sync'd
              </p>
              <p>
                To get started, open Spotify and play any song, then click the
                Check Spotify button to make sure you are connect before you
                start.
              </p>
            </div>
            <div>
              <button
                style={{
                  backgroundColor: primaryColor,
                }}
                className={animate}
                onClick={checkSpotify}
              >
                Check Connection
              </button>
            </div>
          </div>
        </div>
      )}
      <div className='loader' style={{opacity: props.spotifyReady ? 1 : 0}}>Starting Sync...</div>
    </Modal>
  )
}

export default HostFormModal
