// React
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

// MUI Icons
import {
  Close,
  SkipNextRounded,
  SkipPreviousRounded,
  VolumeMute,
} from '@mui/icons-material'

// Contexts
import { SongInfo } from '../../../Resources/Music/Tracks'
import { useThemeContext } from '../../../Contexts/ThemeContext'

// Styling
import './MediaControls.scss'

type MediaControlsProps = {
  dragTime?: (time: number) => void
  playing: boolean
  setPlaying?: () => void
  setSongCurrentTime?: (time: number) => void
  setVolumeLevel: (volumeLevel: number) => void
  skipBack?: () => void
  skipForward?: () => void
  songLength: number
  songCurrentTime: number
  songCurrentInfo: Partial<SongInfo>
  stopCounter?: () => void
  style: string
  volumeLevel: number
}

const MediaControls = (props: MediaControlsProps) => {
  const { pathname: currentPage } = useLocation()
  const { primaryColor } = useThemeContext()

  const [localVolume, setLocalVolume] = useState<number>(50)
  const [muteButton, setMuteButton] = useState<string>('mute-button')
  const [prevVolume, setPrevVolume] = useState<number>(0)
  const [skipLeftButton, setSkipLeftButton] =
    useState<string>('skip-buttons-left')
  const [skipRightButton, setSkipRightButton] =
    useState<string>('skip-buttons-right')
  const [songChange, setSongChange] = useState<string>('')
  const [hovered, setHovered] = useState<string>('#CFD7C7')
  const [waiting, setWaiting] = useState<boolean>(false)

  const timeFormatting = (t: number) => {
    t = Number(t)
    const m = Math.floor((t % 3600) / 60)
    const s = Math.floor((t % 3600) % 60)

    const sDisplay = s > 0 ? (s < 10 ? '0' + s : s) : '00'
    return m + ':' + sDisplay
  }

  const skipBackFunction = useCallback(async () => {
    setWaiting(true)
    if (props.skipBack) {
      await props.skipBack()
      setWaiting(false)
    }
    setSkipLeftButton('prev-animate')
    setSongChange('song-change')
    setTimeout(() => {
      setSkipLeftButton('skip-buttons-left')
      setSongChange('')
    }, 500)
  }, [props.skipBack])

  const skipFunctionRight = useCallback(async () => {
    setWaiting(true)
    if (props.skipForward) {
      await props.skipForward()
      setWaiting(false)
    }
    setSkipRightButton('forw-animate')
    setSongChange('song-change')
    setTimeout(() => {
      setSkipRightButton('skip-buttons-right')
      setSongChange('')
    }, 500)
  }, [props.skipForward])

  const playFunction = useCallback(async () => {
    if (props.setPlaying) {
      await props.setPlaying()
      setWaiting(false)
    }
  }, [props.setPlaying])

  const muteFunction = () => {
    setPrevVolume(0)

    if (localVolume > 0) {
      setPrevVolume(localVolume)
      props.setVolumeLevel(0)
      setLocalVolume(0)
    } else if (localVolume === 0 && prevVolume === 0) {
      props.setVolumeLevel(30)
      setLocalVolume(30)
    } else {
      props.setVolumeLevel(prevVolume)
      setLocalVolume(prevVolume)
    }

    setMuteButton('mute-button-animate')
    setTimeout(() => {
      setMuteButton('mute-button')
    }, 500)
  }

  useEffect(() => {
    !currentPage.includes('host') &&
      !currentPage.includes('guest') &&
      props.setVolumeLevel(localVolume)
  }, [localVolume])

  return (
    <div className='media-bar__container' id={props.style}>
      <svg width={0} height={0}>
        <linearGradient id='linearColors' gradientTransform='rotate(30)'>
          <stop offset='15%' stopColor='#CFD7C7' />
          <stop offset='92%' stopColor={primaryColor} />
        </linearGradient>
      </svg>
      <svg width={0} height={0}>
        <linearGradient id='linearColors2' gradientTransform='rotate(30)'>
          <stop offset='55%' stopColor={primaryColor} />
          <stop offset='92%' stopColor='#CFD7C7' />
        </linearGradient>
      </svg>
      <div className='media-bar__left-section'>
        <div className={`album-art__container ${props.style}`}>
          <img src={props.songCurrentInfo.albumArt}></img>
        </div>
        <div className={`song-info__container ${props.style}`}>
          <a
            style={{
              color: primaryColor,
            }}
            target='_blank'
            href={props.songCurrentInfo.songLink}
            rel='noreferrer'
          >
            <span></span>
            {props.songCurrentInfo.title}
          </a>
          <a
            target='_blank'
            href={props.songCurrentInfo.artistLink!}
            rel='noreferrer'
          >
            <span></span>
            {props.songCurrentInfo.artist}
          </a>
        </div>
      </div>
      <div className='media-bar__middle-section'>
        {props.setPlaying && (
          <div className='media-buttons__container'>
            <div className='skip-buttons__container'>
              <SkipPreviousRounded
                className='skip-button'
                style={{
                  fill: 'url(#linearColors)',
                }}
                onClick={() => !waiting && skipBackFunction()}
              />
              <div className={`skip-button__previous ${skipLeftButton}`}></div>
            </div>
            <div
              className='play-button'
              onClick={() => !waiting && props.setPlaying!()}
            >
              <div
                style={{
                  backgroundImage: `linear-gradient(.25turn, ${primaryColor}, #CFD7C7)`,
                }}
                className={`play-pause ${props.playing ? 'playing' : ''}`}
              ></div>
            </div>
            <div className='skip-buttons__container'>
              <SkipNextRounded
                className='skip-button'
                style={{
                  fill: 'url(#linearColors2)',
                }}
                onClick={() => !waiting && skipFunctionRight()}
              />
              <div className={`skip-button__forward ${skipRightButton}`}></div>
            </div>
          </div>
        )}
        {currentPage.includes('guest') && (
          <div className='song-status__container'>
            {props.playing ? 'Now Playing' : 'Paused by Host'}
          </div>
        )}
        <div className='song-media__container'>
          <div
            className='song-media__bar-container'
            style={{
              top: props.style === 'guest-bar' ? '0%' : '',
            }}
          >
            <div className='song-time__current'>
              {timeFormatting(props.songCurrentTime)}
            </div>
            <div
              onMouseOver={() => setHovered(primaryColor)}
              onMouseLeave={() => setHovered('#CFD7C7')}
              className={`song-media__bar ${
                props.style === 'guest-bar' ? '' : 'not-guest'
              }`}
            >
              <label></label>
              <input
                className='song-media__slider'
                type='range'
                min='0'
                max={props.songLength}
                step='1'
                value={props.songCurrentTime}
                onChange={e => {
                  props.stopCounter && props.stopCounter()
                  props.setSongCurrentTime &&
                    props.setSongCurrentTime(e.target.valueAsNumber)
                }}
                onMouseDown={(e: any) => {
                  props.stopCounter && props.stopCounter()
                }}
                onMouseUp={async (e: any) => {
                  props.dragTime &&
                    (await props.dragTime(props.songCurrentTime))
                }}
              />
              <div
                className={`song-media__progress ${hovered}`}
                style={{
                  width: `${(props.songCurrentTime / props.songLength) * 100}%`,
                  backgroundImage: `linear-gradient(.25turn, ${hovered}, 60%, #CFD7C7)`,
                }}
              ></div>
            </div>
            <div className={`song-time__max ${songChange}`}>
              {!isNaN(props.songLength)
                ? timeFormatting(props.songLength)
                : '0:00'}
            </div>
          </div>
        </div>
      </div>
      <div className='media-bar__right-section'>
        <div className='volume-container'>
          <div
            className='mute-button__container'
            onClick={() => muteFunction()}
          >
            <VolumeMute
              className='volume-icon'
              style={{
                color: primaryColor,
              }}
            />
            <div
              className='sound-wave__one'
              style={{
                opacity: `${localVolume === 0 ? 0 : localVolume / 50 + 0.1}`,
              }}
            ></div>
            <div
              className='sound-wave__two'
              style={{
                opacity: `${
                  localVolume <= 45 ? 0 : (localVolume - 45) / 55 + 0.2
                }`,
              }}
            ></div>
            <Close
              className={`x-icon ${localVolume === 0 ? 'show' : 'dont-show'}`}
            />
            <div className={`mute-button__background ${muteButton}`}></div>
          </div>
        </div>
        <div className='volume-slider__container'>
          <div className='volume-slider'>
            <label></label>
            <input
              className='slider'
              type='range'
              min='0'
              max='100'
              step='1'
              value={localVolume}
              onChange={e => {
                setLocalVolume(e.target.valueAsNumber)
              }}
              onMouseUp={() => props.setVolumeLevel(localVolume)}
              onTouchEnd={() => props.setVolumeLevel(localVolume)}
            />
            <div
              className='progress-bar'
              style={{
                width: `${localVolume}%`,
                backgroundImage: `linear-gradient(.25turn, ${primaryColor}, 60%, #CFD7C7)`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaControls
