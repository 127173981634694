import {
  FC,
  useContext,
  createContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'

export interface ThemeContextParams {
  primaryColor: string
  setPrimaryColor: Dispatch<SetStateAction<string>>
  secondaryColor: string
  setSecondaryColor: Dispatch<SetStateAction<string>>
}

const ThemeContext = createContext({} as ThemeContextParams)

export const useThemeContext = (): ThemeContextParams => {
  return useContext(ThemeContext)
}

export const ThemeContextProvider: FC = ({ children }) => {
  const [primaryColor, setPrimaryColor] = useState<string>(
    window.localStorage.getItem('primaryColor') || '#FB9160',
  )
  const [secondaryColor, setSecondaryColor] = useState<string>(
    window.localStorage.getItem('secondaryColor') || '#CFD7C7',
  )

  useEffect(() => {
    window.localStorage.setItem('primaryColor', primaryColor)
    window.localStorage.setItem('secondaryColor', secondaryColor)
  }, [primaryColor, secondaryColor])

  return (
    <ThemeContext.Provider
      value={{
        primaryColor,
        setPrimaryColor,
        secondaryColor,
        setSecondaryColor,
      }}
    >
      <>{children}</>
    </ThemeContext.Provider>
  )
}
