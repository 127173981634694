import React, { useContext, useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import './Modal.scss'

//@ts-ignore
const ModalContext = React.createContext()

const ModalProvider = ({ children }: { children: any }) => {
  const modalRef = useRef<HTMLElement>()
  const [value, setValue] = useState<HTMLElement>()

  useEffect(() => {
    setValue(modalRef.current)
  }, [])

  return (
    <>
      <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
      {/* @ts-ignore */}
      <div ref={modalRef} />
    </>
  )
}

const Modal = (props: any) => {
  const modalNode: HTMLElement = useContext(ModalContext) as HTMLElement
  if (!modalNode) return null

  return ReactDOM.createPortal(
    <div className={`modal-container ${props.fadeOut}`}>
      <div className='spacer'></div>
      {props.children}
    </div>,
    modalNode,
  )
}

export { Modal, ModalProvider }
