import { FC, useContext, createContext, useEffect, useCallback } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router'
import { GACategory } from '../../types/frontend'

export interface ReactGAContextParams {
  sendGAEvent: (category: GACategory, action: string) => void
}

const ReactGAContext = createContext({} as ReactGAContextParams)

export const useGaContext = (): ReactGAContextParams => {
  return useContext(ReactGAContext)
}

const ReactGAContextProvider: FC = ({ children }) => {
  useEffect(() => {
    try {
      process.env.REACT_APP_STAGE && ReactGA.initialize('G-JMB2W0SYXD')
    } catch (error) {
      console.log(error)
    }
  }, [])

  const { pathname: path } = useLocation()

  useEffect(() => {
    try {
      ReactGA.isInitialized &&
        ReactGA.send({
          hitType: 'pageview',
          path,
        })
    } catch (error) {
      console.log(error)
    }
  }, [path])

  const sendGAEvent = useCallback(
    (category: GACategory, action: string) => {
      try {
        ReactGA.isInitialized &&
          ReactGA.event({
            category,
            action,
          })
      } catch (error) {
        console.error(error)
      }
    },
    [ReactGA],
  )

  return (
    <ReactGAContext.Provider
      value={{
        sendGAEvent,
      }}
    >
      <>{children}</>
    </ReactGAContext.Provider>
  )
}

export { ReactGAContextProvider }
