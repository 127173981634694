export const generateRandomString = (length: number): string => {
  let result = ''
  const characters =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters[Math.floor(Math.random() * charactersLength)]
  }
  return result
}

const sha256 = (plain: string): Promise<ArrayBuffer> => {
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return window.crypto.subtle.digest('SHA-256', data)
}

const base64urlencode = (a: ArrayBuffer): string => {
  // Convert the ArrayBuffer to string using Uint8 array.
  // btoa takes chars from 0-255 and base64 encodes.
  // Then convert the base64 encoded to base64url encoded.
  // (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(a))))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

const pkce_challenge_from_verifier = async (v: string): Promise<string> => {
  const hashed = await sha256(v)
  const base64encoded = base64urlencode(hashed)
  return base64encoded
}

const pkceGenerator = async (
  code_verifier: string,
): Promise<{
  code_verifier: string
  code_challenge: string
}> => {
  const code_challenge = await pkce_challenge_from_verifier(code_verifier)

  return {
    code_verifier,
    code_challenge,
  }
}

export default pkceGenerator
