// React
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

// Components
import Verifying from '../../Components/Authentication/Verifying'
import { useSnackbar } from 'notistack'

// Contexts
import { useSpotifyAuthContext } from '../../Contexts/SpotifyAuthContext'

const AuthPage = (): JSX.Element => {
  // Hooks and Contexts
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { spotifyApi, setSpotifyLoggedIn, logoutSpotify } =
    useSpotifyAuthContext()

  // State
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (isVerified) {
      if (window.localStorage.getItem('from') == 'spotifyCode') {
        navigate('/home')
      } else {
        navigate('/')
      }
    }
  }, [isVerified])

  const sendSpotifyToken = async (token: string): Promise<void> => {
    await fetch('/api/spotifyToken', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        tokenType: 'spotify',
        tokenValue: token,
      }),
    })
  }

  const requestAccessToken = useCallback(
    async (code: string): Promise<Response> => {
      const spotifyTokenUrl = 'https://accounts.spotify.com/api/token'
      const body = new URLSearchParams()
      body.append('grant_type', 'authorization_code')
      body.append('code', code)
      body.append('redirect_uri', spotifyApi.getRedirectURI()!)
      body.append('client_id', spotifyApi.getClientId()!)
      body.append(
        'code_verifier',
        window.localStorage.getItem('code_verifier')!,
      )
      const response = await fetch(spotifyTokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
      })
      return response
    },
    [spotifyApi],
  )

  useEffect(() => {
    const handleLogins = async () => {
      const from = window.localStorage.getItem('from')

      if (from == 'spotifyCode') {
        const code = searchParams.get('code')
        if (code) {
          const response = await requestAccessToken(code)

          if (response.status != 200) {
            logoutSpotify()
            return
          }

          const responseBody = await response.json()
          spotifyApi.setAccessToken(responseBody.access_token)
          spotifyApi.setRefreshToken(responseBody.refresh_token)

          const meResponse = await fetch('https://api.spotify.com/v1/me', {
            headers: {
              Authorization: `Bearer ${responseBody.access_token}`,
            },
          })

          if (response.status != 200) {
            logoutSpotify()
            return
          }

          const meResponseBody = await meResponse.json()

          const { product } = meResponseBody

          if (product !== 'premium') {
            enqueueSnackbar(
              <p>
                Sorry! You must have
                <a
                  href='https://www.spotify.com/us/premium/'
                  target={'_blank'}
                  rel='noreferrer'
                >
                  {' Spotify Premium '}
                </a>{' '}
                to use this app.
              </p>,
              {
                variant: 'warning',
              },
            )
            logoutSpotify()
            return
          }

          window.localStorage.setItem(
            'spotifyAccessToken',
            responseBody.access_token,
          )
          window.localStorage.setItem(
            'spotifyRefreshToken',
            responseBody.refresh_token,
          )
          const currentTime = new Date().getTime()
          window.localStorage.setItem(
            'spotifyAccessExpire',
            (currentTime + parseInt(responseBody.expires_in) * 1000).toString(),
          )

          await sendSpotifyToken(responseBody.access_token)
          setSpotifyLoggedIn(true)
        }
      }

      setIsChecked(true)
    }

    handleLogins()
  }, [])

  return <Verifying isChecked={isChecked} setIsVerified={setIsVerified} />
}

export default AuthPage
