import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router'
import { useState } from 'react'
import './CloseButton.scss'

type CloseButtonProps = {
  setShowModal: (showModal: boolean) => void
  setFadeOut: (fadeOut: string) => void
  redirectLocation?: string
}

const CloseButton = (props: CloseButtonProps): JSX.Element => {
  const navigate = useNavigate()
  const [closeClick, setCloseClick] = useState<string>('')

  const modalFadeOut = () => {
    props.redirectLocation && navigate(props.redirectLocation)
    setCloseClick('animate')
    props.setFadeOut('fade-out')
    setTimeout(() => {
      props.setShowModal(false)
    }, 500)
  }

  return (
    <div
      className={`close-icon__container ${closeClick}`}
      onClick={() => modalFadeOut()}
    >
      <CloseIcon className='close-icon' />
    </div>
  )
}

export default CloseButton
