// React
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Components
import { GuestCheck } from '../../Global/Modals'
import JukeOffGuest from './JukeOffGuest'
import { Verifying } from '../../Authentication'

// Contexts
import { useQueueContext } from '../../../Contexts/QueueContext'
import { useSpotifyAuthContext } from '../../../Contexts/SpotifyAuthContext'
import { useSpotifyControlContext } from '../../../Contexts/SpotifyControlContext'

// Types
import { HostInfo } from '../../../../types/api'

// WebSocket
import WebSocket from 'isomorphic-ws'
import { websocketUrl } from '../../../Utils/websocketUrl'
import { Queue } from '../../../../types/frontend'

const Guest = (): JSX.Element => {
  const { connectionId } = useParams()
  const navigate = useNavigate()

  const { spotifyApi } = useSpotifyAuthContext()
  const {
    guestCommands,
    socket,
    setSocket,
    playerState,
    setHostConnectionId,
    hostInfo,
    setHostInfo,
  } = useSpotifyControlContext()
  const queue = useQueueContext()
  const [rollover, setRollover] = useState<boolean>(false)

  // Song search state
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [position, setPosition] = useState<number>(0)
  const [showModal, setShowModal] = useState<boolean>(true)
  const [ticker, toggleTicker] = useState<boolean>(false)
  const [tickerInterval, setTickerInterval] = useState<any>()

  const tick = useCallback(async () => {
    if (playerState.playing) {
      if (position < (playerState.songLength - 1500) / 1000) {
        setPosition(() =>
          Math.round(
            (playerState.songLength - (playerState.songEnd - Date.now())) /
              1000,
          ),
        )
      } else {
        setRollover(true)
        setPosition(0)
      }
    }
  }, [playerState, position, tickerInterval])

  useEffect(() => {
    tick()
  }, [ticker])

  const onClose = () => {
    navigate('/home')
  }

  useEffect(() => {
    if (rollover || !playerState.playing) {
      clearInterval(tickerInterval)
    } else {
      setTickerInterval(
        setInterval(() => {
          toggleTicker(prev => !prev)
        }, 1000),
      )
    }

    return () => {
      clearInterval(tickerInterval)
    }
  }, [playerState, rollover])

  const onMessage = useCallback(
    async (e: any) => {
      const body = JSON.parse(e.data)

      if (connectionId && socket) {
        if (body.info?.command && body.info?.type === 'updateQueue') {
          queue.set(body.info.queue)
        } else if (body.info?.command && guestCommands) {
          setRollover(false)
          switch (body.info?.type) {
            case 'play':
              await guestCommands.play({
                contextUri: body.info.hostInfo.playerState.contextUri,
                positionMs:
                  body.info.hostInfo.playerState.songLength -
                  (body.info.hostInfo.playerState.songEnd - Date.now()),
              })
              break
            case 'pause':
              await guestCommands.pause()
              break
          }
          await queue.get(body.info.hostInfo.hostConnectionId)
        } else if (body.source == 'guestConnect' && connectionId) {
          if (!body.info?.success) {
            navigate('/home')
          }
          const {
            hostInfo: newHostInfo,
            queue: newQueue,
          }: { hostInfo: HostInfo; queue: Queue } = body.info
          queue.set(newQueue)
          setHostInfo(newHostInfo)
          setHostConnectionId(newHostInfo.hostConnectionId)
        } else if (body.source == 'hostDisconnect') {
          socket?.close()
        }
      }
    },
    [socket, connectionId, guestCommands],
  )

  const authorize = async (): Promise<boolean> => {
    return await fetch('/api/socketAuth', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        tokenType: 'spotify',
        tokenValue: spotifyApi.getAccessToken(),
      }),
    }).then(res => res.status === 200)
  }

  // 1. INIT SOCKET
  useEffect(() => {
    const init = async () => {
      const authorized = await authorize()
      if (authorized) {
        const socket = new WebSocket(websocketUrl())
        socket.onopen = () => {
          socket.send(
            JSON.stringify({
              action: 'guestConnect',
              hostConnectionId: connectionId,
            }),
          )
        }
        socket.onclose = onClose
        socket.onmessage = onMessage
        setSocket(socket)
      } else {
        navigate('/home')
      }
    }
    init()
  }, [])

  useEffect(() => {
    if (socket) socket.onmessage = onMessage
  }, [onMessage, socket])

  useEffect(() => {
    let pingInterval: any
    if (socket) {
      pingInterval = setInterval(() => {
        socket.send(
          JSON.stringify({
            action: 'ping',
          }),
        )
      }, 1000 * 240)
    }
    return () => {
      clearInterval(pingInterval)
    }
  }, [socket])

  return (
    <>
      {!isVerified && (
        <Verifying isChecked={!!hostInfo} setIsVerified={setIsVerified} />
      )}
      {showModal && isVerified && hostInfo && (
        <GuestCheck setShowModal={setShowModal} hostInfo={hostInfo} />
      )}
      {isVerified && <JukeOffGuest position={position} />}
    </>
  )
}

export default Guest
