import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { ModalProvider } from './Contexts/Modals'

import './index.scss'

Sentry.init({
  dsn: 'https://f050a7889dce4f5184a31da6635d167d@o1384768.ingest.sentry.io/6703630',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_STAGE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <ModalProvider>
      <Router>
        <App />
      </Router>
    </ModalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
