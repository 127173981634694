// React
import { Routes, Route } from 'react-router-dom'

// Components
import AuthPage from '../src/Pages/Authentication'
import { GuestPage, HomePage, HostPage } from './Pages/Authenticated'
import SplashPage from './Pages/Unauthenticated'
import { PP } from './Components/Global/PP'
import { PageLayout, ErrorPage } from './Components/Global'

// Context
import Contexts from './Contexts/Contexts'

// Styling
import './App.scss'

const App = () => {
  return (
    <Contexts>
      <div className='App'>
        <PageLayout>
          <Routes>
            <Route path='/auth' element={<AuthPage />} />
            <Route path='/home' element={<HomePage />} />
            <Route path='/host' element={<HostPage />} />
            <Route path='/guest/:connectionId' element={<GuestPage />} />
            <Route path='/' element={<SplashPage />} />
            <Route path='privacy' element={<PP />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </PageLayout>
      </div>
    </Contexts>
  )
}

export default App
