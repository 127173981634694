import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useQueueContext } from './QueueContext'
import { useSpotifyAuthContext } from './SpotifyAuthContext'
import {
  defaultPlayerState,
  useSpotifyControlContext,
} from './SpotifyControlContext'

const ContextManager: FC = ({ children }): JSX.Element => {
  const queue = useQueueContext()
  const { setPlayerState, setHostConnectionId, socket, setSocket } =
    useSpotifyControlContext()

  const { pathname } = useLocation()

  useEffect(() => {
    if (!(pathname.includes('host') || pathname.includes('guest'))) {
      setPlayerState(defaultPlayerState)
      queue.set([])
      setHostConnectionId('')
      if (socket) {
        socket.onclose = () => null
        socket.close()
      }
      setSocket(undefined)
    }
  }, [pathname])

  const bigTesting = false
  if (bigTesting) {
    console.log(useSpotifyAuthContext())
    console.log(useSpotifyControlContext())
    console.log(useQueueContext())
  }

  return <>{children}</>
}

export { ContextManager }
