// React
import { useState } from 'react'
import { Modal } from '../../../../Contexts/Modals'
import { CloseButton } from '../../Buttons'

// Stylings
import './WalkthroughModal.scss'

type WalkThroughModal = {
  setShowModal: (showModal: boolean) => void
  redirectLocation?: string
}

const WalkthroughModal = (props: WalkThroughModal): JSX.Element => {
  const [fadeOut, setFadeOut] = useState<string>('')

  return (
    <Modal fadeOut={fadeOut}>
      <div className='walkthrough-modal__container'>
        <CloseButton
          setShowModal={props.setShowModal}
          setFadeOut={setFadeOut}
        />
        <iframe
          src='https://www.youtube.com/embed/ZAe1RoLt0bg'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen'
        ></iframe>
      </div>
    </Modal>
  )
}

export default WalkthroughModal
