// React
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { HostFormModal, LoginModal } from '../../Global/Modals'
import { QueueJukeBox } from '../Queue'
import { MediaControls, SearchBar, SongCards } from '../../Global'

// WebSocket
import WebSocket from 'isomorphic-ws'
import { websocketUrl } from '../../../Utils/websocketUrl'

// Contexts
import { useQueueContext } from '../../../Contexts/QueueContext'
import { useSpotifyAuthContext } from '../../../Contexts/SpotifyAuthContext'
import { useSpotifyControlContext } from '../../../Contexts/SpotifyControlContext'
import { commaFunction } from '../../../Utils/commaMaker'

// Types
import { HostConnectBody, HostInfo } from '../../../../types/api'
import './HostPage.scss'

const HostPageComponent = (): JSX.Element => {
  const queue = useQueueContext()

  const [submitSuccessful, setSubmitSuccessful] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [updatingSong, setUpdatingSong] = useState<boolean>(false)
  const [searchStarted, setSearchStarted] = useState<boolean>(false)

  // Contexts and hooks
  const { spotifyApi, spotifyProfile, spotifyLoggedIn } =
    useSpotifyAuthContext()
  const { playerState, setPlayerState, hostCommands } =
    useSpotifyControlContext()
  const {
    socket,
    setSocket,
    hostConnectionId,
    setHostConnectionId,
    setHostInfo,
  } = useSpotifyControlContext()
  const navigate = useNavigate()

  // Submission state
  const [explicitMusic, setExplicitMusic] = useState<boolean>(true)
  const [spotifyReady, setSpotifyReady] = useState<boolean>(false)

  // Song search state
  const [songSearchResult, setSongSearchResult] = useState<
    SpotifyApi.TrackObjectFull[]
  >([])
  const [tickerInterval, setTickerInterval] = useState<any>()
  const [ticker, toggleTicker] = useState<boolean>(false)
  const [position, setPosition] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedSong, setSelectedSong] = useState<SpotifyApi.TrackObjectFull>()
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false)

  // Socket Functions
  const onClose = () => {
    console.log('websocket closed')
    navigate('/home')
  }

  useEffect(() => {
    const timeoutFunction = setTimeout(() => {
      if (!spotifyLoggedIn) setShowLoginModal(true)
    }, 3000)

    return () => clearTimeout(timeoutFunction)
  }, [spotifyLoggedIn])

  const onMessage = useCallback(
    async (e: any) => {
      const data = JSON.parse(e.data)
      if (data.source == 'hostConnect') {
        setSubmitSuccessful(data.success)
        setHostConnectionId(data.connectionId)
        setHostInfo(prev => {
          if (prev) {
            const newHostInfo = {
              ...prev,
            }
            newHostInfo.hostConnectionId = data.connectionId
            return newHostInfo
          }
          return prev
        })
        window.localStorage.setItem('hostConnectionId', data.connectionId)
      }
      if (data.source == 'hostUpdate' && socket && hostConnectionId) {
        await queue.get(hostConnectionId)
      }
    },
    [hostConnectionId, playerState, queue.get],
  )

  const authorize = useCallback(async (): Promise<boolean> => {
    return await fetch('/api/socketAuth', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        tokenType: 'spotify',
        tokenValue: spotifyApi.getAccessToken(),
      }),
    }).then(res => res.status === 200)
  }, [spotifyApi])

  const initStreamSocket = () => {
    const socket = new WebSocket(websocketUrl())
    socket.onopen = async e => {
      const initialHostInfo: HostInfo = {
        explicitMusic,
        hostAlbumArt: (
          (await spotifyApi.getMyCurrentPlayingTrack()).body
            .item as SpotifyApi.TrackObjectFull
        ).album.images[0].url,
        hostConnectionId,
        hostSpotifyDisplayName: spotifyProfile?.display_name ?? '',
        hostSpotifyId: spotifyProfile?.id ?? '',
        playerState,
      }
      setHostInfo(initialHostInfo)
      socket.send(
        JSON.stringify({
          action: 'hostConnect',
          info: initialHostInfo,
        } as HostConnectBody),
      )
    }
    socket.onclose = onClose
    socket.onmessage = onMessage
    setSocket(socket)
  }

  // Song search functions

  useEffect(() => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'

    const random = Math.floor(Math.random() * characters.length)

    const searchSongStart = async () => {
      await spotifyApi
        .searchTracks(characters.slice(random, random + 1), {
          limit: 20,
        })
        .then(res => {
          if (res.statusCode == 200 && res.body.tracks)
            setSongSearchResult(
              res.body.tracks.items.filter(track => {
                return track.explicit != true
              }),
            )
        })
    }

    searchSongStart()
  }, [])

  useEffect(() => {
    spotifyApi
      .searchTracks(searchValue, {
        limit: 20,
      })
      .then(res => {
        if (res.statusCode == 200 && res.body.tracks)
          setSongSearchResult!(
            res.body.tracks.items.filter(track => {
              return explicitMusic ? true : track.explicit != true
            }),
          )
      })
  }, [searchStarted])

  // Triggered when the host form is submitted
  useEffect(() => {
    if (submitted) {
      const init = async () => {
        const authorized = await authorize()
        if (authorized) {
          initStreamSocket()
        } else {
          navigate('/home')
        }
      }
      init()
    }
  }, [submitted])

  useEffect(() => {
    const initPlayer = async () => {
      const currentPlayback = await spotifyApi.getMyCurrentPlaybackState()
      if (
        currentPlayback &&
        currentPlayback.body &&
        currentPlayback.body.item
      ) {
        const currentItem = currentPlayback.body
          .item as SpotifyApi.TrackObjectFull
        setPlayerState({
          requester: {
            spotifyDisplayName: spotifyProfile?.display_name ?? '',
            spotifyId: spotifyProfile?.id ?? '',
          },
          type: 'host',
          timeCaptured: '',
          title: currentItem.name,
          artist: commaFunction(currentItem.artists),
          coverArt: currentItem.album.images[0].url,
          playing: false,
          songLength: currentItem.duration_ms,
          songPosition: currentPlayback.body.progress_ms ?? 0,
          songStart: Date.now() - (currentPlayback.body.progress_ms ?? 0),
          songEnd:
            Date.now() -
            (currentPlayback.body.progress_ms ?? 0) +
            currentItem.duration_ms,
          volume: 50,
          songLink: currentPlayback.body.item?.external_urls.spotify,
          artistLink: currentItem.artists[0].external_urls.spotify,
          contextUri: currentItem.uri,
        })
        setPosition(Math.round((currentPlayback.body.progress_ms ?? 0) / 1000))
      }
    }

    initPlayer()
  }, [spotifyReady])

  const tick = useCallback(async () => {
    if (
      playerState.playing &&
      hostCommands &&
      hostConnectionId &&
      !updatingSong
    ) {
      if (position < (playerState.songLength - 2500) / 1000) {
        setPosition(() =>
          Math.round(
            (playerState.songLength - (playerState.songEnd - Date.now())) /
              1000,
          ),
        )
      } else {
        setPosition(0)
        setUpdatingSong(true)
        await hostCommands.next(queue)
        setUpdatingSong(false)
      }
    }
  }, [
    playerState,
    position,
    tickerInterval,
    hostConnectionId,
    hostCommands,
    queue,
  ])

  useEffect(() => {
    tick()
  }, [ticker])

  useEffect(() => {
    if (!playerState.playing || updatingSong) {
      clearInterval(tickerInterval)
      setTickerInterval(null)
    } else {
      !tickerInterval &&
        setTickerInterval(
          setInterval(() => {
            toggleTicker(prev => !prev)
          }, 1000),
        )
    }

    return () => {
      clearInterval(tickerInterval)
    }
  }, [playerState, updatingSong])

  useEffect(() => {
    if (hostConnectionId && socket) {
      socket.onmessage = onMessage
      queue.get(hostConnectionId)
    }
  }, [hostConnectionId])

  useEffect(() => {
    let pingInterval: any
    if (socket) {
      pingInterval = setInterval(() => {
        socket.send(
          JSON.stringify({
            action: 'ping',
          }),
        )
      }, 1000 * 30)
    }

    return () => {
      clearInterval(pingInterval)
    }
  }, [socket])

  const hostQueueSong = useCallback(
    async (songInfo: SpotifyApi.TrackObjectFull) => {
      delete songInfo.available_markets
      delete songInfo.album.available_markets

      if (socket) {
        await queue.add(songInfo, hostConnectionId, 'host')
      }
    },
    [socket, hostConnectionId, playerState, queue.add],
  )

  useEffect(() => {
    if (selectedSong) hostQueueSong(selectedSong)
  }, [selectedSong])

  const skipBackFunction = useCallback(async () => {
    clearInterval(tickerInterval)
    await hostCommands.back()
  }, [hostCommands, queue])

  const skipForwardFunction = useCallback(async () => {
    setUpdatingSong(true)
    await hostCommands.next(queue)
    setUpdatingSong(false)
  }, [hostCommands, queue])

  const dragTime = useCallback(
    async (time: number) => {
      setUpdatingSong(true)
      await hostCommands.drag(time * 1000)
      setUpdatingSong(false)
    },
    [hostCommands, setUpdatingSong],
  )

  const stopCounter = useCallback(() => {
    setUpdatingSong(true)
  }, [])

  const setSongCurrentTime = useCallback(
    async (time: number) => {
      setUpdatingSong(true)
      setPosition(time)
    },
    [tickerInterval],
  )

  return (
    <div className='host-page__container'>
      {showLoginModal && (
        <LoginModal redirectLocation='/home' setShowModal={setShowLoginModal} />
      )}
      {!submitSuccessful && (
        <HostFormModal
          explicitMusic={explicitMusic}
          setExplicitMusic={setExplicitMusic}
          submitSuccessful={submitSuccessful}
          setSubmitted={setSubmitted}
          spotifyReady={spotifyReady}
          setSpotifyReady={setSpotifyReady}
        />
      )}
      <SearchBar
        setSearchStarted={setSearchStarted}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <div className='host-page__cards-queue-container'>
        <SongCards
          searchStarted={searchStarted}
          songSearchResult={songSearchResult}
          setSelectedSong={setSelectedSong}
        />
        <QueueJukeBox style={'host'} />
      </div>
      <div className='media-controls__container'>
        {hostCommands && spotifyReady && (
          <MediaControls
            playing={playerState.playing}
            setPlaying={
              playerState.playing ? hostCommands.pause : hostCommands.play
            }
            skipBack={skipBackFunction}
            skipForward={skipForwardFunction}
            volumeLevel={playerState.volume}
            setVolumeLevel={hostCommands.volume}
            songLength={playerState.songLength / 1000}
            dragTime={dragTime}
            stopCounter={stopCounter}
            setSongCurrentTime={setSongCurrentTime}
            songCurrentTime={position}
            songCurrentInfo={{
              albumArt: playerState.coverArt,
              artist: playerState.artist,
              title: playerState.title,
              songLink: playerState.songLink,
              artistLink: playerState.artistLink,
            }}
            style={'host-bar'}
          />
        )}
      </div>
    </div>
  )
}

export default HostPageComponent
