// React
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

// Contexts
import { useLayoutContext } from '../../../Contexts/LayoutContext'
import { useThemeContext } from '../../../Contexts/ThemeContext'

// Images
import splashBackgroundImg from '../Images/splashbackground.jpg'
import { SSSvg } from '../../../Resources/Logo/logo'

// Styling
import './PageLayout.scss'

type PageLayoutProps = {
  children: JSX.Element
}

const PageLayout = (props: PageLayoutProps): JSX.Element => {
  // react
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // contexts
  const { menuItems, modals, customMenuItems, setNavBarHovered } =
    useLayoutContext()
  const { primaryColor } = useThemeContext()

  // state
  const [navPosition, setNavPosition] = useState<string>('')
  const [selected, setSelected] = useState<number>()
  const [selectedBackground, setSelectedBackground] =
    useState<string>('transparent')

  useEffect(() => {
    modals.reduce((acc, item) => {
      return acc || item.show
    }, false)
      ? () => {
          null
        }
      : setSelected(undefined)
  }, [modals])

  return (
    <div
      className='page-layout__container'
      style={{
        backgroundImage: `url(${splashBackgroundImg})`,
      }}
    >
      {!pathname.includes('auth') ? (
        <>
          <div
            className={`nav-bar__container ${navPosition}`}
            onMouseOver={() => {
              setNavPosition('open')
              setSelectedBackground(primaryColor)
              setNavBarHovered(true)
            }}
            onMouseLeave={() => {
              setNavPosition('')
              setSelectedBackground('transparent')
              setNavBarHovered(false)
            }}
          >
            <div
              className='nav-bar__logo--container'
              onClick={() => navigate('/home')}
            >
              <SSSvg
                height='4vw'
                width='4vw'
                sColor='#090A0C'
                circleColor='transparent'
                waveColor={primaryColor}
              />
              <p>StreamSync</p>
              <div className={`${navPosition}`}></div>
            </div>
            {customMenuItems.map((item, i) => {
              return (
                <div key={i} className='custom-item__container'>
                  {item}
                </div>
              )
            })}
            {menuItems.map((item, i) => {
              return (
                <div
                  key={i}
                  className='nav-bar__item--container'
                  style={{
                    backgroundColor:
                      selected === i ? `${selectedBackground}` : '',
                  }}
                  onClick={e => {
                    item.onClick(e)
                    item.highlight && setSelected(i)
                  }}
                >
                  <div
                    className='menu-item__icon--container'
                    style={{
                      backgroundColor: selected === i ? `${primaryColor}` : '',
                    }}
                  >
                    <item.icon
                      className={`menu-item__icon ${
                        selected === i ? 'selected' : ''
                      }`}
                    />
                  </div>
                  <p
                    className={`menu-item__text ${
                      selected === i ? 'selected' : ''
                    }`}
                  >
                    {item.text}
                  </p>
                </div>
              )
            })}
          </div>
          <div className={`page-content__container ${navPosition}`}>
            {modals.map(modal => modal.show && modal.modal)}
            {props.children}
          </div>
        </>
      ) : (
        props.children
      )}
    </div>
  )
}

export default PageLayout
