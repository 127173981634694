// React
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useThemeContext } from '../../../../Contexts/ThemeContext'
import { useLayoutContext } from '../../../../Contexts/LayoutContext'
import GroupsIcon from '@mui/icons-material/Groups'
import './StartSyncButton.scss'

const StartSyncButton = (): JSX.Element => {
  const navigate = useNavigate()
  const [hovered, setHovered] = useState<string>('transparent')
  const { primaryColor } = useThemeContext()
  const { navBarHovered } = useLayoutContext()

  return (
    <div
      onMouseOver={() => setHovered(primaryColor)}
      onClick={() => navigate('/host')}
      onMouseLeave={() => setHovered('transparent')}
      className='start-sync__btn--container'
      style={{
        boxShadow: `0 0 5px ${hovered}, 0 0 20px ${hovered}`,
      }}
    >
      {!navBarHovered ? (
        <>
          <div className='menu-item__icon--container'>
            <GroupsIcon className='menu-item__icon' />
            <span
              className='span-1'
              style={{
                backgroundImage: `linear-gradient(90deg, transparent, ${primaryColor})`,
                display: hovered != 'transparent' ? 'none' : '',
              }}
            ></span>
            <span
              className='span-2'
              style={{
                backgroundImage: `linear-gradient(180deg, transparent, ${primaryColor})`,
                display: hovered != 'transparent' ? 'none' : '',
              }}
            ></span>
            <span
              className='span-3'
              style={{
                backgroundImage: `linear-gradient(270deg, transparent, ${primaryColor})`,
                display: hovered != 'transparent' ? 'none' : '',
              }}
            ></span>
            <span
              className='span-4'
              style={{
                backgroundImage: `linear-gradient(360deg, transparent, ${primaryColor})`,
                display: hovered != 'transparent' ? 'none' : '',
              }}
            ></span>
          </div>
          <p>Start Sync</p>
        </>
      ) : (
        <>
          <div className='menu-item__icon--container'>
            <GroupsIcon
              className='menu-item__icon'
              style={{
                color: hovered != 'transparent' ? primaryColor : '',
              }}
            />
          </div>
          <p>Start Sync</p>
        </>
      )}
    </div>
  )
}

export default StartSyncButton
