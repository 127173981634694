// React
import { useState } from 'react'

// Packages
import { useSnackbar } from 'notistack'

// Contexts
import { useSpotifyControlContext } from '../../../../Contexts/SpotifyControlContext'
import { justWait } from '../../../../Utils/tools'
import { useSpotifyAuthContext } from '../../../../Contexts/SpotifyAuthContext'
import { useThemeContext } from '../../../../Contexts/ThemeContext'

// Modal
import { Modal } from '../../../../Contexts/Modals'

// Styles
import './GuestCheck.scss'
import { HostInfo } from '../../../../../types/api'

type GuestCheckProps = {
  setShowModal: (showModal: boolean) => void
  hostInfo: HostInfo
}

const GuestCheck = (props: GuestCheckProps) => {
  const { spotifyApi } = useSpotifyAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const { guestCommands } = useSpotifyControlContext()
  const { primaryColor } = useThemeContext()

  const [animate, setAnimate] = useState<string>('')
  const [fadeOut, setFadeOut] = useState<string>('')

  const checkSpotify = async (e: any) => {
    setAnimate('animate')
    setTimeout(() => {
      setAnimate('')
    }, 500)

    const playPause = async () => {
      await guestCommands.play({
        contextUri: props.hostInfo.playerState.contextUri,
        positionMs: props.hostInfo.playerState.songPosition,
      })
      if (!props.hostInfo.playerState.playing) {
        await guestCommands.pause()
      }
    }

    const res = await spotifyApi.getMyCurrentPlayingTrack()
    if (res.statusCode != 200) {
      enqueueSnackbar('Unable to connect to Spotify', {
        autoHideDuration: 3000,
        variant: 'error',
      })
    } else {
      enqueueSnackbar('Connected to Spotify', {
        autoHideDuration: 3000,
        variant: 'success',
      })
      await playPause()
      setFadeOut('fade-out')
      setTimeout(() => {
        props.setShowModal(false)
      }, 400)
    }
  }

  return (
    <Modal fadeOut={fadeOut}>
      <div className='check-spotify__container'>
        <div>
          <p
            style={{
              color: primaryColor,
            }}
          >
            Get Sync'd
          </p>
        </div>
        <div>
          <p>
            To get started, open Spotify and play any song, then click the Check
            Spotify button to make sure you are connect before you start.
          </p>
        </div>
        <div>
          <button
            style={{
              backgroundColor: primaryColor,
            }}
            className={animate}
            onClick={checkSpotify}
          >
            Check Connection
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default GuestCheck
