// React
import { useEffect, useState, useMemo } from 'react'

// Components
import HostPageComponent from '../../Components/Authenticated/HostPage'

// Contexts
import {
  MenuItem,
  useLayoutContext,
  PageModal,
} from '../../Contexts/LayoutContext'
import { useSpotifyAuthContext } from '../../Contexts/SpotifyAuthContext'

// Icons
import { Help, Palette, AccountBox } from '@mui/icons-material'

// Modals
import { AboutPageModal, ThemeModal } from '../../Components/Global/Modals'

const HostPage = (): JSX.Element => {
  // Contexts
  const { setMenuItems, setModals, setCustomMenuItems } = useLayoutContext()
  const { logoutSpotify, loginSpotify, spotifyLoggedIn } =
    useSpotifyAuthContext()

  // State
  const [fromAboutModal, setFromAboutModal] = useState<boolean>(false)
  const [showAboutModal, setShowAboutModal] = useState<boolean>(false)
  const [showCollaborateModal, setShowCollaborateModal] =
    useState<boolean>(false)
  const [showThemeModal, setShowThemeModal] = useState<boolean>(false)

  const showSelectedModal = (modal: string) => {
    if (modal === 'about') {
      setShowAboutModal(true)
      setShowThemeModal(false)
    } else if (modal === 'theme') {
      setShowAboutModal(false)
      setShowThemeModal(true)
    }
  }

  const customMenuItems: JSX.Element[] = useMemo(() => {
    return []
  }, [])

  const menuItems: MenuItem[] = useMemo(() => {
    return [
      {
        icon: AccountBox,
        text: spotifyLoggedIn ? 'Logout' : 'Login',
        onClick: spotifyLoggedIn ? () => logoutSpotify() : () => loginSpotify(),
      },
      {
        icon: Help,
        text: 'About',
        onClick: () => showSelectedModal('about'),
        highlight: true,
      },
      {
        icon: Palette,
        text: 'Theme',
        onClick: () => showSelectedModal('theme'),
        highlight: true,
      },
    ]
  }, [showAboutModal, showThemeModal, logoutSpotify])

  const modals: PageModal[] = useMemo(() => {
    return [
      {
        modal: (
          <AboutPageModal
            setFromAbout={setFromAboutModal}
            setShowAboutModal={setShowAboutModal}
            setShowCollabModal={setShowCollaborateModal}
          />
        ),
        show: showAboutModal,
        setShow: setShowAboutModal,
      },
      {
        modal: <ThemeModal setShowModal={setShowThemeModal} />,
        show: showThemeModal,
        setShow: setShowThemeModal,
      },
    ]
  }, [showThemeModal, showAboutModal])

  useEffect(() => {
    setMenuItems(menuItems)
    setModals(modals)
    setCustomMenuItems(customMenuItems)
  }, [
    setMenuItems,
    setModals,
    showThemeModal,
    showAboutModal,
    logoutSpotify,
    spotifyLoggedIn,
  ])

  return <HostPageComponent />
}

export default HostPage
