// React
import { useState } from 'react'
import { Modal } from '../../../../Contexts/Modals'
import { useThemeContext } from '../../../../Contexts/ThemeContext'
import { useSpotifyAuthContext } from '../../../../Contexts/SpotifyAuthContext'
import { CloseButton } from '../../Buttons'

// Stylings
import './LoginModal.scss'

type LoginModalProps = {
  setShowModal: (showModal: boolean) => void
  redirectLocation?: string
}

const LoginModal = (props: LoginModalProps): JSX.Element => {
  const { primaryColor } = useThemeContext()
  const { loginSpotify } = useSpotifyAuthContext()
  const [fadeOut, setFadeOut] = useState<string>('')

  return (
    <Modal fadeOut={fadeOut}>
      <div className='login-modal__container'>
        <CloseButton
          redirectLocation={props.redirectLocation}
          setShowModal={props.setShowModal}
          setFadeOut={setFadeOut}
        />
        <p>Please Login To Continue</p>
        <button
          style={{
            backgroundColor: primaryColor,
          }}
          onClick={() => loginSpotify()}
        >
          Login
        </button>
      </div>
    </Modal>
  )
}

export default LoginModal
