const map = (
  value: number,
  prevLow: number,
  prevHigh: number,
  newLow: number,
  newHigh: number,
): number => {
  return (
    ((value - prevLow) / (prevHigh - prevLow)) * (newHigh - newLow) + newLow
  )
}

export default map
