import {
  FC,
  useContext,
  createContext,
  useEffect,
  useState,
  SetStateAction,
  useCallback,
  Dispatch,
} from 'react'

import pkceGenerator, { generateRandomString } from '../Utils/pkceGenerator'
import buildUrl from 'build-url'
import SpotifyWebApi from 'spotify-web-api-node'

import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'

interface SpotifyAuthContextParams {
  spotifyApi: SpotifyWebApi
  logoutSpotify: () => void
  loginSpotify: () => void
  spotifyLoggedIn: boolean
  setSpotifyLoggedIn: Dispatch<SetStateAction<boolean>>
  spotifyProfile?: SpotifyApi.CurrentUsersProfileResponse
}

const SpotifyAuthContext = createContext({} as SpotifyAuthContextParams)

export const useSpotifyAuthContext = (): SpotifyAuthContextParams => {
  return useContext(SpotifyAuthContext)
}

const sendSpotifyToken = async (token: string): Promise<void> => {
  await fetch('/api/spotifyToken', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      tokenType: 'spotify',
      tokenValue: token,
    }),
  })
}

const SpotifyAuthContextProvider: FC = ({ children }) => {
  // Hooks
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  // State
  const [spotifyCodeUrl, setSpotifyCodeUrl] = useState<string>('')
  const [spotifyLoggedIn, setSpotifyLoggedIn] = useState<boolean>(false)
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false)
  const triggerRefresh = useCallback(() => {
    setToggleRefresh(prev => !prev)
  }, [setToggleRefresh])
  const [spotifyProfile, setSpotifyProfile] =
    useState<SpotifyApi.CurrentUsersProfileResponse>()

  // API Info
  const SPOTIFY_AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize'
  const SPOTIFY_CLIENT_ID =
    process.env.REACT_APP_STAGE == 'prod'
      ? 'b47154b111cd45bcade90004058cf551'
      : '7c7cf4e555ca441391576c6353164d2d'
  const SPOTIFY_REDIRECT_URI = `${window.location.origin}/auth`
  const SPOTIFY_SCOPE_LIST = [
    'user-read-playback-state',
    'user-modify-playback-state',
    'user-read-currently-playing',
    'streaming',
    'user-read-private',
  ]
  const SPOTIFY_SCOPES = SPOTIFY_SCOPE_LIST.join(' ')
  const SPOTIFY_RESPONSE_TYPE = 'code'
  const SPOTIFY_CODE_CHALLENGE_METHOD = 'S256'

  const spotifyApi = new SpotifyWebApi({
    redirectUri: SPOTIFY_REDIRECT_URI,
    clientId: SPOTIFY_CLIENT_ID,
  })

  spotifyApi.setAccessToken(
    window.localStorage.getItem('spotifyAccessToken') ?? '',
  )
  spotifyApi.setRefreshToken(
    window.localStorage.getItem('spotifyRefreshToken') ?? '',
  )

  const logoutSpotify = useCallback(() => {
    const clearKeys = [
      'from',
      'code_challenge',
      'code_verifier',
      'spotifyAccessToken',
      'spotifyRefreshToken',
      'spotifyAccessExpire',
    ]
    clearKeys.forEach(key => window.localStorage.removeItem(key))
    spotifyApi.resetAccessToken()
    spotifyApi.resetClientId()
    spotifyApi.resetClientSecret()
    spotifyApi.resetCredentials()
    spotifyApi.resetRedirectURI()
    spotifyApi.resetRefreshToken()
    triggerRefresh()
    setSpotifyLoggedIn(false)
    navigate('/')
  }, [spotifyApi])

  const loginSpotify = useCallback(() => {
    window.localStorage.removeItem('from')
    window.localStorage.setItem('from', 'spotifyCode')
    window.location.href = spotifyCodeUrl
  }, [spotifyCodeUrl])

  useEffect(() => {
    const buildSpotifyUrl = async () => {
      const verifier = generateRandomString(100)
      const pkcePair = await pkceGenerator(verifier)
      const spotifyUrl = buildUrl(SPOTIFY_AUTH_ENDPOINT, {
        queryParams: {
          client_id: SPOTIFY_CLIENT_ID,
          redirect_uri: SPOTIFY_REDIRECT_URI,
          response_type: SPOTIFY_RESPONSE_TYPE,
          scope: SPOTIFY_SCOPES,
          code_challenge_method: SPOTIFY_CODE_CHALLENGE_METHOD,
          code_challenge: pkcePair.code_challenge,
        },
      })
      window.localStorage.setItem('code_challenge', pkcePair.code_challenge)
      window.localStorage.setItem('code_verifier', pkcePair.code_verifier)
      window.localStorage.removeItem('from')
      setSpotifyCodeUrl(spotifyUrl)
    }

    const from = window.localStorage.getItem('from')

    if ((!from && !spotifyLoggedIn) || from == 'logout') {
      window.localStorage.removeItem('from')
      buildSpotifyUrl()
    }
  }, [spotifyLoggedIn, toggleRefresh])

  useEffect(() => {
    const refresh = async () => {
      const body = new URLSearchParams()

      const grantType = 'refresh_token'
      const refreshToken = window.localStorage.getItem('spotifyRefreshToken')

      if (!refreshToken) {
        enqueueSnackbar('Unable to refresh Spotify token', {
          variant: 'error',
        })
        logoutSpotify()
        return
      }

      let newTokenShit

      if (refreshToken) {
        body.append('grant_type', grantType)
        body.append('refresh_token', refreshToken)
        body.append('client_id', SPOTIFY_CLIENT_ID)

        newTokenShit = await fetch('https://accounts.spotify.com/api/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body,
        }).then(res => (res.status === 200 ? res.json() : null))
      }

      if (!newTokenShit) {
        enqueueSnackbar('Unable to refresh Spotify token', {
          variant: 'error',
        })
        logoutSpotify()
        return
      }

      window.localStorage.setItem(
        'spotifyAccessToken',
        newTokenShit.access_token,
      )
      window.localStorage.setItem(
        'spotifyRefreshToken',
        newTokenShit.refresh_token,
      )
      spotifyApi.setAccessToken(newTokenShit.access_token)
      const currentTime = new Date().getTime()
      window.localStorage.setItem(
        'spotifyAccessExpire',
        (currentTime + parseInt(newTokenShit.expires_in) * 1000).toString(),
      )
      await sendSpotifyToken(newTokenShit.access_token)
      const me = (await spotifyApi.getMe()).body
      setSpotifyProfile(me)
      setSpotifyLoggedIn(true)
      triggerRefresh()
    }

    let time: any

    if (window.localStorage.getItem('spotifyAccessExpire')) {
      const now = Date.now()
      const expiry = parseInt(
        window.localStorage.getItem('spotifyAccessExpire')!,
      )
      const refreshTime = expiry - 120000

      if (now > refreshTime || !spotifyLoggedIn) {
        refresh()
      } else {
        time = setTimeout(async () => {
          await refresh()
        }, refreshTime - now)
      }
    }

    return () => {
      if (time) {
        clearTimeout(time)
      }
    }
  }, [spotifyLoggedIn, toggleRefresh])

  useEffect(() => {
    const updateProfile = async () =>
      setSpotifyProfile((await spotifyApi.getMe())?.body)
    if (spotifyLoggedIn) {
      updateProfile()
    }
  }, [spotifyLoggedIn])

  return (
    <SpotifyAuthContext.Provider
      value={{
        spotifyApi,
        logoutSpotify,
        loginSpotify,
        spotifyLoggedIn,
        setSpotifyLoggedIn,
        spotifyProfile,
      }}
    >
      {children}
    </SpotifyAuthContext.Provider>
  )
}

export { SpotifyAuthContextProvider }
