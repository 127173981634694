import { WebSocket } from 'isomorphic-ws'
import {
  FC,
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react'
import { JukeboxCapInfo, JukeboxSearch, SongTypes } from '../../types/api'
import { useSpotifyAuthContext } from './SpotifyAuthContext'
import { useSnackbar } from 'notistack'
import { useSpotifyControlContext } from './SpotifyControlContext'
import { Requester } from '../../types/api'
import { QueueEntry, Queue } from '../../types/frontend'
import { useLocation } from 'react-router'

export interface ReturnQueue {
  next: QueueEntry
  queue: Queue
}

export interface SongQueueContextParams {
  add: (
    songInfo: SpotifyApi.TrackObjectFull,
    hostConnectionId: string,
    type: SongTypes,
  ) => Promise<void>
  get: (hostConnectionId: string) => Promise<ReturnQueue>
  next: (hostConnectionId: string) => Promise<QueueEntry | void>
  queue: Queue
  remove: (hostConectionId: string, timeCaptured: string) => Promise<void>
  set: Dispatch<SetStateAction<Queue>>
}

const QueueContext = createContext({} as SongQueueContextParams)

export const useQueueContext = (): SongQueueContextParams => {
  return useContext(QueueContext)
}

const QueueContextProvider: FC = ({ children }) => {
  const { spotifyApi, spotifyProfile } = useSpotifyAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const { playerState, socket, hostInfo } = useSpotifyControlContext()
  const { pathname } = useLocation()

  const [queue, setQueue] = useState<Queue>([])

  const get = useCallback(
    async (
      hostConnectionId: string,
    ): Promise<{ queue: Queue; next: QueueEntry }> => {
      const getResponse = await fetch('/api/jukeboxGetQueue', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          hostConnectionId,
        }),
      })
      const { queue: returnQueue, next } = await getResponse.json()
      setQueue(returnQueue)
      return {
        next,
        queue: returnQueue,
      }
    },
    [playerState, spotifyApi, queue, socket, setQueue],
  )

  const remove = useCallback(
    async (timeCaptured: string, hostConnectionId: string): Promise<void> => {
      enqueueSnackbar('Removing Song...')

      const removeResponse = await fetch('/api/jukeboxRemove', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          hostConnectionId,
          timeCaptured,
        } as JukeboxSearch),
      })

      if (removeResponse.status !== 200) {
        setQueue([])
      } else {
        enqueueSnackbar('Song removed')
        const { queue: newQueue } = await removeResponse.json()
        setQueue(newQueue)
      }
    },
    [spotifyApi],
  )

  const next = useCallback(
    async (hostConnectionId: string): Promise<QueueEntry | undefined> => {
      enqueueSnackbar('Loading next song')

      const nextResponse = await fetch('api/jukeboxNext', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          hostConnectionId,
        }),
      })

      if (nextResponse.status != 200) {
        enqueueSnackbar('Failed to load queue')
        setQueue([])
        return
      } else {
        enqueueSnackbar('Updating queue')
        const { next, queue: newQueue } = await nextResponse.json()
        setQueue(newQueue)
        return next as QueueEntry
      }
    },
    [get],
  )

  const add = useCallback(
    async (
      songInfo: SpotifyApi.TrackObjectFull,
      hostConnectionId: string,
      type: SongTypes,
    ) => {
      enqueueSnackbar(`Adding ${songInfo.name} to the queue`)
      const me = (await spotifyApi.getMe()).body
      const requester: Requester = {
        spotifyId: me.id,
        spotifyDisplayName: me.display_name ?? '',
      }
      await fetch('/api/jukeboxCap', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          hostConnectionId,
          timeCaptured: '',
          requester,
          songInfo,
          hostInfo,
          capDetails: {},
          type,
        } as JukeboxCapInfo),
      })

      await get(hostConnectionId)
    },
    [spotifyApi, playerState, socket, spotifyProfile, hostInfo, get],
  )

  useEffect(() => {
    pathname.includes('host') &&
      socket?.send(
        JSON.stringify({
          action: '$default',
          info: {
            command: true,
            type: 'updateQueue',
            queue,
          },
          playerState,
        }),
      )
    socket && enqueueSnackbar('Updating guest queues...')
  }, [queue])

  return (
    <QueueContext.Provider
      value={{
        add,
        get,
        next,
        queue,
        remove,
        set: setQueue,
      }}
    >
      <>{children}</>
    </QueueContext.Provider>
  )
}

export { QueueContextProvider }
