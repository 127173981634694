import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import { useThemeContext } from '../../../Contexts/ThemeContext'
import './SearchBar.scss'

type SearchBarProps = {
  setSearchStarted: (searchStarted: boolean) => void
  searchValue: string
  setSearchValue: (searchValue: string) => void
}

const SearchBar = (props: SearchBarProps): JSX.Element => {
  const { pathname } = useLocation()
  const { primaryColor } = useThemeContext()
  const [searchIconClick, setSearchIconClick] = useState<string>('')
  const [focusedColor, setFocusedColor] = useState<string>('')

  const clickFunc = () => {
    props.setSearchStarted(true)
    setSearchIconClick('animate')
    setTimeout(() => {
      setSearchIconClick('')
    }, 500)
    setTimeout(() => {
      props.setSearchStarted(false)
    }, 1000)
  }

  return (
    <div className='sync-search__container'>
      <div className='search-section'>
        <form
          className='search-bar__form'
          autoComplete='off'
          onSubmit={e => e.preventDefault()}
          role='search'
        >
          <label htmlFor='search'></label>
          <input
            onBlur={() => setFocusedColor('')}
            onFocus={() => setFocusedColor(primaryColor)}
            value={props.searchValue}
            onKeyDown={e => {
              if (e.key === 'Enter') clickFunc()
            }}
            onChange={e => props.setSearchValue(e.target.value)}
            className={'search-bar'}
            type='search'
            placeholder='Search...'
            spellCheck='false'
            style={{
              borderColor: focusedColor,
            }}
          />
          <button className='search-bar__btn' type='submit'>
            <SearchIcon
              className={`search-btn__icon ${searchIconClick}`}
              onClick={() => clickFunc()}
              style={{
                color: primaryColor,
              }}
            />
          </button>
        </form>
      </div>
      {(pathname.includes('/guest') || pathname.includes('/host')) && (
        <div className='queue-section'>
          <p
            style={{
              backgroundImage: `linear-gradient(.25turn, ${primaryColor}, #CFD7C7, ${primaryColor})`,
            }}
          >
            Song Queue
          </p>
        </div>
      )}
    </div>
  )
}

export default SearchBar
