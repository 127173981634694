// React
import { useState, useCallback, FC } from 'react'

// MUI Icons
import DeleteIcon from '@mui/icons-material/DeleteForever'

// Contexts
import { useSpotifyControlContext } from '../../../Contexts/SpotifyControlContext'
import { useQueueContext } from '../../../Contexts/QueueContext'
import { useThemeContext } from '../../../Contexts/ThemeContext'

import { QueueEntry } from '../../../../types/frontend'

// Stylings
import './QueueJukeBox.scss'

interface CloseProps {
  timeCaptured: string
}

const Close: FC<CloseProps> = ({ timeCaptured }) => {
  const { socket, hostConnectionId } = useSpotifyControlContext()
  const queue = useQueueContext()

  const [closeClick, setCloseClick] = useState<string>('')
  const [waiting, setWaiting] = useState<boolean>(false)

  const removeSong = useCallback(() => {
    if (socket && hostConnectionId) {
      setWaiting(true)
      setCloseClick('animate')
      setTimeout(async () => {
        setCloseClick('')
        await queue.remove(timeCaptured, hostConnectionId)
      }, 400)
    }
  }, [waiting, queue])

  return (
    <DeleteIcon
      className={`delete-icon ${closeClick}`}
      onClick={() => !waiting && removeSong()}
    />
  )
}

type QueueJukeBoxProps = {
  style: string
}

const QueueJukeBox = (props: QueueJukeBoxProps): JSX.Element => {
  const queue = useQueueContext()
  const { primaryColor } = useThemeContext()

  const timeFormating = (t: number) => {
    t = Number(t)
    const m = Math.floor((t % 3600) / 60)
    const s = Math.floor((t % 3600) % 60)

    const sDisplay = s > 0 ? (s < 10 ? '0' + s : s) : '00'
    return m + ':' + sDisplay
  }

  return (
    <div className='song-queue__container'>
      <div className='queue-item__container--juke-on'>
        {queue.queue.map((qSong: QueueEntry, i: any) => {
          return (
            <div key={i} className='queue-item'>
              <section>
                <p>{i + 1}.</p>
              </section>
              <section>
                <div>
                  <img src={qSong.songInfo.album.images[0].url} />
                </div>
                <div>
                  <div>
                    <p
                      style={{
                        backgroundImage: `linear-gradient(.25turn, ${primaryColor}, #CFD7C7, ${primaryColor})`,
                      }}
                    >
                      {qSong.songInfo.name}
                    </p>
                  </div>
                  <div>
                    <p>
                      {qSong.songInfo.artists.length > 1
                        ? qSong.songInfo.artists.reduce(
                            // @ts-ignore
                            (
                              all: SpotifyApi.ArtistObjectSimplified,
                              artist: any,
                            ) => `${all.name ? all.name : all}, ${artist.name}`,
                          )
                        : qSong.songInfo.artists[0].name ?? 'N/A'}
                    </p>
                  </div>
                </div>
                <div>{timeFormating(qSong.songInfo.duration_ms / 1000)}</div>
                <div className='hover-container' id='hover-container'>
                  <div
                    style={{
                      width: props.style === 'host' ? '80%' : '100%',
                    }}
                  >
                    <div>
                      <a
                        href={qSong.songInfo.external_urls.spotify}
                        target='_blank'
                        style={{
                          color: primaryColor,
                        }}
                        rel='noreferrer'
                      >
                        <span></span>
                        View Song
                      </a>
                      <a
                        className='song-requestor'
                        href={`http://open.spotify.com/user/${qSong.requester.spotifyId}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span></span>Played by:{' '}
                        {qSong.requester.spotifyDisplayName}
                      </a>
                    </div>
                  </div>
                  {props.style === 'host' && (
                    <div key={qSong.timeCaptured} className='close-container'>
                      <div className='delete-icon__container'>
                        <Close timeCaptured={qSong.timeCaptured} />
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default QueueJukeBox
